<template>
    <div>
      <HeaderComponent />
      <HeroComponent />
      <AdvantagesComponent />
      <ServicesComponent />
      <LawyersComponent />
      <ContactFormComponent />
      <FooterComponent />
    </div>
  </template>
  
  <script>
  import HeaderComponent from "@/components/HeaderComponent.vue";
  import HeroComponent from "@/components/HeroComponent.vue";
  import AdvantagesComponent from "@/components/AdvantagesComponent.vue";
  import LawyersComponent from "@/components/LawyersComponent.vue";
  import ServicesComponent from "@/components/ServicesComponent.vue";
  import ContactFormComponent from "@/components/ContactFormComponent.vue";
  import FooterComponent from "@/components/FooterComponent.vue";
  
  export default {
    name: 'HomeView',
    components: {
      HeaderComponent,
      HeroComponent,
      AdvantagesComponent,
      ServicesComponent,
      LawyersComponent,
      ContactFormComponent,
      FooterComponent
    }
  };
  </script>
  
  <style scoped>
  /* Дополнительные стили для HomeView.vue */
  </style>
  